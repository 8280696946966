<template>
  <div>
    <b-modal id="promotionSaveModal" size="xl" centered>
      <template #modal-header>&nbsp;</template>
      <template #default="{ close }">
        <div class="layers big">
          <div class="layer-contents pb-3">
            <div class="row justify-content-start px-3">
              <div class="pop_tit">
                <div class="col-lg-7">
                  가입은 <span class="inline-block"><img src="../assets/images/img-num.png" /></span
                  ><span class="weight700">분</span>이내 완료됩니다.
                </div>
              </div>
            </div>
            <div class="bg-white border-radius pb-4 px-3">
              <div class="row justify-content-center py-4">
                <div class="col-lg-10">
                  <div class="text-center">
                    <h2 class="mb-2 weight500 fs-22 font-purple">
                      상담 예약시 익일 근무시작 시간 (10시~18시) 안에 전문 상담사가 전화드립니다.
                    </h2>
                    <p class="mb-0 f-18 font-gray">(남겨 놓으신 정보는 상담을 위해서만 사용됩니다.)</p>
                  </div>
                </div>
              </div>
              <div class="row justify-content-between p-3">
                <div class="col-lg-6 col-md-12 px-xxl-0 mb-4">
                  <h4 class="tit-h4"><span class="line-height20">상호명</span></h4>
                  <span class="label"
                    ><input type="text" class="inputSt" placeholder="상호명을 입력하세요" v-model="name"
                  /></span>
                </div>
                <div class="col-lg-6 col-md-12 px-xxl-0 mb-4">
                  <h4 class="tit-h4 pt_4">
                    <span class="line-height20 block">가게<br />유선전화번호</span>
                  </h4>
                  <span class="label"
                    ><b-form-select
                      :options="telOptions"
                      v-model="tempTel1"
                      class="wid30"
                      style="vertical-align: top"
                    ></b-form-select
                    >&nbsp;<span class="wid30"
                      ><input
                        type="number"
                        class="inputSt"
                        placeholder="숫자4자리"
                        v-model="tempTel2"
                        min="0"
                        max="9999"
                        maxlength="4"
                        @input="maxLengthLimit" /></span
                    >&nbsp;<span class="wid30"
                      ><input
                        type="number"
                        class="inputSt"
                        placeholder="숫자4자리"
                        v-model="tempTel3"
                        min="0"
                        max="9999"
                        maxlength="4"
                        @input="maxLengthLimit" /></span
                  ></span>
                </div>
                <div class="col-lg-6 col-md-12 px-xxl-0 mb-4">
                  <h4 class="tit-h4"><span class="line-height20">이름</span></h4>
                  <span class="label"
                    ><input type="text" class="inputSt" placeholder="이름을 입력하세요" v-model="joinName"
                  /></span>
                </div>
                <div class="col-lg-6 col-md-12 px-xxl-0 mb-4">
                  <h4 class="tit-h4 pt_4">
                    <span class="line-height20 block">상담가능</span
                    ><span class="line-height20 block">휴대폰 번호</span>
                  </h4>
                  <span class="label"
                    ><b-form-select
                      :options="contactOptions"
                      v-model="tempContact1"
                      class="wid30"
                      style="vertical-align: top"
                    ></b-form-select
                    >&nbsp;<span class="wid30"
                      ><input
                        type="number"
                        class="inputSt"
                        placeholder="숫자4자리"
                        v-model="tempContact2"
                        min="0"
                        max="9999"
                        maxlength="4"
                        @input="maxLengthLimit" /></span
                    >&nbsp;<span class="wid30"
                      ><input
                        type="number"
                        class="inputSt"
                        placeholder="숫자4자리"
                        v-model="tempContact3"
                        min="0"
                        max="9999"
                        maxlength="4"
                        @input="maxLengthLimit" /></span
                  ></span>
                </div>
              </div>
              <div class="row justify-content-start px-3">
                <ul class="list">
                  <li class="f-17">수집 및 이용목적 : 가입처리를 위한 안내/답변</li>
                  <li class="f-17">수집 및 이용항목 : 성함, 연락처(유선,무선),상호명</li>
                  <li class="f-17">보유 및 이용기간 : 수집 및 이용 목적 달성 시 까지</li>
                </ul>
                <div class="agreement">
                  <input type="checkbox" id="agreement" class="checkbox" v-model="isAgree" /><label for="agreement"
                    >개인정보처리방침 동의</label
                  >
                </div>
              </div>
              <p class="mt-4 wid40 m-auto block">
                <a href="#" class="btn btn-lg black" @click="pomotionComplete">상담예약 (터치)</a>
              </p>
            </div>
            <div class="layer-close top30">
              <button type="button" @click="close">
                <span><img src="../assets/images/layer_close.png" alt="레이어 닫기" /></span>
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>&nbsp;</template>
    </b-modal>
    <b-modal id="completeModal" centered>
      <template #default="{ close }">
        <div class="layerWrap">
          <div class="layers white">
            <div class="layer-contents">
              <p class="popup-cont pt-1">
                접수가 완료되었습니다.<br />영업일로 익일 9시~18시 사이에<br />전화드리겠습니다.
              </p>
              <div class="btn-area">
                <a href="#" class="btn medium purple" @click="close">확인</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal id="failModal" centered>
      <template #default="{ close }">
        <div class="layerWrap">
          <div class="layers white">
            <div class="layer-contents">
              <p class="popup-cont pt-1">{{ failMessage }}</p>
              <div class="btn-area">
                <a href="#" class="btn medium purple" @click="close">확인</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { promotionSave } from '@/api/promotion'

export default {
  data: () => ({
    submit: {
      joinName: '',
      name: '',
      tel: '',
      contact: '',
      ringoPart: 3,
    },
    isAgree: false,
    tel1: '02',
    tel2: '',
    tel3: '',
    contact1: '010',
    contact2: '',
    contact3: '',
    telOptions: [
      { text: '02', value: '02' },
      { text: '031', value: '031' },
      { text: '032', value: '032' },
      { text: '033', value: '033' },
      { text: '041', value: '041' },
      { text: '042', value: '042' },
      { text: '043', value: '043' },
      { text: '044', value: '044' },
      { text: '051', value: '051' },
      { text: '052', value: '052' },
      { text: '053', value: '053' },
      { text: '054', value: '054' },
      { text: '055', value: '055' },
      { text: '061', value: '061' },
      { text: '062', value: '062' },
      { text: '063', value: '063' },
      { text: '064', value: '064' },
      { text: '070', value: '070' },
    ],
    contactOptions: ['010', '011', '016', '017', '018', '019'],
    failMessage: '',
  }),
  computed: {
    joinName: {
      set(val) {
        this.submit.joinName = val
      },
      get() {
        return this.submit.joinName
      },
    },
    name: {
      set(val) {
        this.submit.name = val
      },
      get() {
        return this.submit.name
      },
    },
    tempTel1: {
      set(val) {
        this.tel1 = val
        this.submit.tel = `${this.tel1}${this.tel2}${this.tel3}`
      },
      get() {
        return this.tel1
      },
    },
    tempTel2: {
      set(val) {
        this.tel2 = val
        this.submit.tel = `${this.tel1}${this.tel2}${this.tel3}`
      },
      get() {
        return this.tel2
      },
    },
    tempTel3: {
      set(val) {
        this.tel3 = val
        this.submit.tel = `${this.tel1}${this.tel2}${this.tel3}`
      },
      get() {
        return this.tel3
      },
    },
    tempContact1: {
      set(val) {
        this.contact1 = val
        this.submit.contact = `${this.contact1}${this.contact2}${this.contact3}`
      },
      get() {
        return this.contact1
      },
    },
    tempContact2: {
      set(val) {
        this.contact2 = val
        this.submit.contact = `${this.contact1}${this.contact2}${this.contact3}`
      },
      get() {
        return this.contact2
      },
    },
    tempContact3: {
      set(val) {
        this.contact3 = val
        this.submit.contact = `${this.contact1}${this.contact2}${this.contact3}`
      },
      get() {
        return this.contact3
      },
    },
  },
  methods: {
    async pomotionComplete() {
      if (this.submit.name.length == 0) {
        this.failMessage = '상호명을 입력해주세요'
        this.$bvModal.show('failModal')
        return false
      }
      if (this.submit.joinName.length == 0) {
        this.failMessage = '이름을 입력해주세요'
        this.$bvModal.show('failModal')
        return false
      }

      if (this.submit.tel.length < 4) {
        this.failMessage = '가게 유선전화번호를 입력해주세요'
        this.$bvModal.show('failModal')
        return false
      }

      if (this.submit.contact.length < 4) {
        this.failMessage = '상담가능 휴대폰 번호를 입력해주세요'
        this.$bvModal.show('failModal')
        return false
      }

      var telRegexp = /^(0[2-7]{1}[0-9]{0,1})(\d{3,4})(\d{4})$/g

      if (!telRegexp.test(this.submit.tel)) {
        this.failMessage = '전화번호 형식에 맞지 않습니다.(가게 유선전화번호)'
        this.$bvModal.show('failModal')
        return false
      }

      var contactRegexp = /^(01[0-9]{1})(\d{3,4})(\d{4})$/g

      if (!contactRegexp.test(this.submit.contact)) {
        this.failMessage = '전화번호 형식에 맞지 않습니다.(상담가능 휴대폰 번호)'
        this.$bvModal.show('failModal')
        return false
      }

      if (!this.isAgree) {
        this.failMessage = '개인정보처리방침에 동의해주세요.'
        this.$bvModal.show('failModal')
        return false
      }

      const result = await promotionSave(this.submit)
      if (result.data) {
        if (result.data.jsonResult) {
          this.failMessage = ''
          this.submit.joinName = ''
          this.submit.name = ''
          this.contact1 = '010'
          this.contact2 = ''
          this.contact3 = ''
          this.tel1 = '02'
          this.tel2 = ''
          this.tel3 = ''
          this.joinName = ''
          this.name = ''
          this.isAgree = false
          this.$bvModal.hide('promotionSaveModal')
          this.$bvModal.show('completeModal')
        } else {
          this.failMessage = '접수에 실패했습니다.'
          this.$bvModal.show('failModal')
        }
      } else {
        this.failMessage = '접수에 실패했습니다.'
        this.$bvModal.show('failModal')
      }
    },
    maxLengthLimit() {
      this.tempTel2 = this.tempTel2.slice(0, 4)
      this.tempTel3 = this.tempTel3.slice(0, 4)
      this.tempContact2 = this.tempContact2.slice(0, 4)
      this.tempContact3 = this.tempContact3.slice(0, 4)
    },
    setRingoPart(ringoPart) {
      this.submit.ringoPart = ringoPart
    },
  },
}
</script>

<style>
#promotionSaveModal___BV_modal_header_,
#promotionSaveModal___BV_modal_footer_,
#completeModal___BV_modal_header_,
#completeModal___BV_modal_footer_,
#failModal___BV_modal_header_,
#failModal___BV_modal_footer_ {
  display: none;
}
#promotionSaveModal___BV_modal_body_,
#completeModal___BV_modal_body_,
#failModal___BV_modal_body_ {
  padding: 0;
}
#promotionSaveModal___BV_modal_content_,
#completeModal___BV_modal_content_,
#failModal___BV_modal_content_ {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
