<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import '@/assets/scss/bootstrap.scss';
html {
  scroll-behavior: smooth;
}
</style>
