import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      name: 'Main',
      component: () => import('./../components/Main.vue'),
    },
    {
      path: '*',
      name: 'Main',
      component: () => import('./../components/Main.vue'),
    },
  ],
})

export default router
