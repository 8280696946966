<template>
  <div class="layerWrap">
    <div class="layers small">
      <b-modal :id="customId" :size="size" centered>
        <template #modal-header="{ close }">
          <div class="layer-header">
            <h2 class="bullet">
              V링고 고객 <span class="weight700">{{ title }}</span> 제작, 이용사례
            </h2>
          </div>
          <div class="layer-close top30">
            <button type="button" @click="close">
              <span><img src="../assets/images/layer_close.png" alt="레이어 닫기" /></span>
            </button>
          </div>
        </template>
        <template #default>
          <div class="layer-contents">
            <slot></slot>
          </div>
        </template>
        <template #modal-footer="{ close }" class="py-0">
          <b-container>
            <b-row class="ustify-content-between px-1">
              <b-col lg="10">영상 정보 : {{ info }}</b-col>
              <b-col lg="2">
                <a class="btn purple" style="height: 37px" @click="close">닫기</a>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customId: {
      required: true,
    },
    title: {
      required: true,
    },
    info: {
      required: true,
    },
    size: {
      default: 'lg',
    },
  },
  computed: {
    videoInfo() {
      return `${this.videoItem.style} / ${this.videoItem.category} / ${this.videoItem.genre}`
    },
  },
}
</script>

<style>
#videoModal___BV_modal_content_ {
  padding: 0.75rem 1rem !important;
}
.modal-backdrop {
  opacity: 0.4;
}
</style>
