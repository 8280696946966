<template>
  <div class="container">
    <h2 class="tit-text mb-3">실시간 가입자현황</h2>
    <div class="bg-white border-radius">
      <div class="row justify-content-between p-3">
        <div class="col-lg-3 col-md-6 border-dashed my-4">
          <div class="text-center">
            <h4 class="h4 mb-2">상호</h4>
            <img src="../assets/images/ung-realtime1.png" />
            <ul class="mt-2 slide">
              <li v-for="(item, index) in list" :key="'name' + index">
                <transition name="slide-fade">
                  <p v-if="arrayIndex.includes(index)">{{ item.name | maskingName }}</p>
                </transition>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 border-dashed my-4">
          <div class="text-center" style="height: 14.8rem">
            <h4 class="h4 mb-2">가입번호</h4>
            <img src="../assets/images/ung-realtime1.png" />
            <ul class="mt-2 slide">
              <li v-for="(item, index) in list" :key="'tel' + index">
                <transition name="slide-fade">
                  <p v-if="arrayIndex.includes(index)">{{ item.tel | maskingPhoneNumber }}</p>
                </transition>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 border-dashed my-4">
          <div class="text-center">
            <h4 class="h4 mb-2">업종</h4>
            <img src="../assets/images/ung-realtime1.png" />
            <ul class="mt-2 slide">
              <li v-for="(item, index) in list" :key="'work' + index">
                <transition name="slide-fade">
                  <p v-if="arrayIndex.includes(index)">{{ item.workAs | workStr }}</p>
                </transition>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 my-4">
          <div class="text-center">
            <h4 class="h4 mb-2">총 누적 가입자</h4>
            <img src="../assets/images/ung-realtime1.png" />
            <ul class="mt-4">
              <li>
                <span class="fs-42 font-pink weight700">{{ totalCount | numberWithCommas }}</span> 건
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { subscribeList } from '@/api/promotion'
export default {
  data: () => ({
    list: [
      { name: '', tel: '', work: '' },
      { name: '', tel: '', work: '' },
    ],
    totalCount: 0,
    count: 0,
    countInterval: undefined,
  }),
  computed: {
    visibleList() {
      const array = this.list.slice(this.count % 20, (this.count % 20) + 3)
      if (array.length < 3) {
        array.push(this.list[0])
      }
      if (array.length < 3) {
        array.push(this.list[1])
      }
      return array
    },
    arrayIndex() {
      const array = []
      const array2 = this.list.slice(this.count % 20, (this.count % 20) + 2)
      array.push(this.count % 20)
      array.push((this.count % 20) + 1)
      if (array2.length < 2) {
        array.push(0)
      }

      return array
    },
  },
  filters: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    maskingPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{2,3})(\d{3,4})(\d{4})$/)
      if (match) {
        return '***- ' + match[2].slice(0, 1) + '** - ***' + match[3].slice(3, 4)
      }

      return phoneNumberString
    },
    maskingName(x) {
      if (x.length > 5) {
        const length = x.length
        return `${x.slice(0, 1)}***${x.slice(length - 1, length)}`
      } else {
        return '*****'
      }
    },
    workStr(x) {
      if (x) {
        const text = x.replace('[localdata]', '')
        return text.replace('[localview]', '')
      } else {
        return ''
      }
    },
  },
  async mounted() {
    const { data } = await subscribeList()

    if (data.list) {
      this.list = data.list.map(element => {
        return {
          idx: element.idx,
          inputDt: element.inputDt,
          name: element.name,
          si: element.si,
          tel: element.tel,
          workAs: element.workAs,
          workIdx: element.workIdx,
        }
      })
      this.totalCount = data.totalCount
      this.countInterval = setInterval(() => {
        this.count += 1
      }, 3000)
    }
  },
  updated() {
    if (this.list) {
      if (this.countInterval) {
        clearInterval(this.countInterval)
        this.countInterval = setInterval(() => {
          this.count += 1
        }, 3000)
      }
    }
  },
  destroyed() {
    if (this.countInterval) {
      clearInterval(this.countInterval)
    }
  },
}
</script>

<style scoped>
ul.mt-2.slide {
  height: 4.125rem;
  overflow: hidden;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-40px);
  opacity: 0;
  top: -40px;
  height: 0px;
}

.slide-fade-enter-active {
  animation: slide-fade-in 0.4s;
}

.slide-fade-leave-active {
  animation: slide-fade-out 0.6s;
}

@keyframes slide-fade-out {
  0% {
    transform: translateY(0px);
    opacity: 1;
    height: 33px;
  }
  10% {
    transform: translateY(-4px);
    opacity: 0.9;
    height: 30px;
  }
  20% {
    transform: translateY(-8px);
    opacity: 0.8;
    height: 26px;
  }
  30% {
    transform: translateY(-12px);
    opacity: 0.7;
    height: 22px;
  }
  40% {
    transform: translateY(-16px);
    opacity: 0.6;
    height: 19px;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0.5;
    height: 16px;
  }
  60% {
    transform: translateY(-24px);
    opacity: 0.4;
    height: 14px;
  }
  70% {
    transform: translateY(-28px);
    opacity: 0.3;
    height: 12px;
  }
  80% {
    transform: translateY(-32px);
    opacity: 0.2;
    height: 8px;
  }
  90% {
    transform: translateY(-36px);
    opacity: 0.1;
    height: 4px;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
    height: 0px;
  }
}

@keyframes slide-fade-in {
  0% {
    transform: translateY(40px);
    opacity: 0;
    height: 0px;
  }
  10% {
    transform: translateY(36px);
    opacity: 0.9;
    height: 3px;
  }
  20% {
    transform: translateY(32px);
    opacity: 0.1;
    height: 6px;
  }
  30% {
    transform: translateY(28px);
    opacity: 0.2;
    height: 9px;
  }
  40% {
    transform: translateY(24px);
    opacity: 0.3;
    height: 13px;
  }
  50% {
    transform: translateY(20px);
    opacity: 0.5;
    height: 16px;
  }
  60% {
    transform: translateY(16px);
    opacity: 0.4;
    height: 19px;
  }
  70% {
    transform: translateY(12px);
    opacity: 0.3;
    height: 22px;
  }
  80% {
    transform: translateY(8px);
    opacity: 0.2;
    height: 26px;
  }
  90% {
    transform: translateY(4px);
    opacity: 0.1;
    height: 30px;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    height: 33px;
  }
}
</style>
