<template>
  <div class="col-lg-6 mb-4 pt-2">
    <div class="service-box text-center bg-common">
      <div class="service-icon py-4 px-2">
        <img src="../assets/images/img-01.png" class="w-100" />
      </div>
      <div class="service-content mt-4">
        <img src="../assets/images/img-tit.png" />
      </div>
      <div class="tblComm2">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <colgroup>
            <col width="30%" />
            <col width="32%" />
            <col width="24%" />
            <col width="14%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">장르</th>
              <th scope="col">업종</th>
              <th scope="col">설정시간</th>
              <th scope="col">듣기</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in showAudioArray" :key="'audio-' + index">
              <td>{{ item.genre }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.setting }}</td>
              <td>
                <button type="button" v-b-modal="'audioModal'" @click="audioClick(item)">
                  <img src="../assets/images/btn_play.png" alt="듣기" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <PlayAudioModal :audioItem="audioItem"></PlayAudioModal>
  </div>
</template>

<script>
import PlayAudioModal from '@/components/PlayAudioModal.vue'
import { audioArray } from '@/api/array.js'
export default {
  data: () => ({
    audioItem: {},
    count: 0,
    countInterval: undefined,
  }),
  computed: {
    showAudioArray() {
      const array = audioArray.slice(this.count % 20, (this.count % 20) + 4)
      if (array.length < 4) {
        array.push(audioArray[0])
      }
      if (array.length < 4) {
        array.push(audioArray[1])
      }
      if (array.length < 4) {
        array.push(audioArray[2])
      }
      return array
    },
  },
  mounted() {
    this.countInterval = setInterval(() => {
      this.count += 1
    }, 3000)
  },
  updated() {
    if (this.countInterval) {
      clearInterval(this.countInterval)
      this.countInterval = setInterval(() => {
        this.count += 1
      }, 3000)
    }
  },
  methods: {
    audioClick(item) {
      this.audioItem = item
    },
  },
  components: {
    PlayAudioModal,
  },
}
</script>

<style>
div.tblComm2 table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
  height: 62px;
}
</style>
