<template>
  <CommonModal :customId="'audioModal'" :size="'md'" :title="'음성'" :info="audioInfo">
    <video
      controls
      width="100%"
      :src="audioItem.src"
      autoplay
      controlsList="nodownload"
      poster="../assets/images/music_01.gif"
    ></video>
  </CommonModal>
</template>

<script>
import CommonModal from './CommonModal.vue'

export default {
  props: {
    audioItem: {
      required: true,
    },
  },
  computed: {
    audioInfo() {
      return `${this.audioItem.setting} / ${this.audioItem.category} / ${this.audioItem.genre}`
    },
  },
  components: {
    CommonModal,
  },
}
</script>
