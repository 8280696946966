import { api_post, apiDefault, optionFunc } from '@/api'

export async function subscribeList() {
  const option = optionFunc({
    url: 'subscribeList.html',
  })
  return await api_post(apiDefault, option)
}

export async function promotionSave(params) {
  const option = optionFunc({
    url: 'promotionSave.html',
    params,
  })
  return await api_post(apiDefault, option)
}
