<template>
  <CommonModal :customId="'videoModal'" :title="'영상'" :info="videoInfo">
    <video controls width="100%" :src="videoItem.src" autoplay :poster="videoItem.thumbnail"></video>
  </CommonModal>
</template>

<script>
import CommonModal from './CommonModal.vue'
export default {
  props: {
    videoItem: {
      required: true,
    },
  },
  computed: {
    videoInfo() {
      return `${this.videoItem.style} / ${this.videoItem.category} / ${this.videoItem.genre}`
    },
  },
  components: {
    CommonModal,
  },
}
</script>
