import axios from 'axios'

const API_SRC = `${process.env?.VUE_APP_API_URL ?? ''}/api`

const create = (url, options) => {
  const instance = axios.create(Object.assign({ baseURL: url }, options))
  return instance
}

export function optionFunc({ url, headers, params, data }) {
  return {
    url,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers,
    },
    params,
    data,
    withCredentials: false,
  }
}

const api_request = (apiInstance, option) => {
  return apiInstance
    .request(option)
    .then(result => {
      return result
    })
    .catch(err => {
      if (process.env.NODE_ENV === 'department') {
        if (err.response) {
          console.log(err.response.data)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log(err.message)
        }
      }

      return { data: {}, status: 1 }
    })
}

export const api_post = (apiInstance, option) => {
  option.method = 'post'
  return api_request(apiInstance, option)
}

//  각종 API axios instance
export const apiDefault = create(`${API_SRC}`)
