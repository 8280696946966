<template>
  <div id="app">
    <!--스크롤 시 nav-sticky 추가-->
    <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light nav-sticky">
      <!--//스크롤 시 nav-sticky 추가-->
      <div class="container">
        <a href="#scrollspy_description" class="navbar-brand logo" v-smooth-scroll
          ><img src="../assets/images/logo_light.png" class="logo-light"
        /></a>
        <div class="collapse navbar-collapse">
          <b-nav class="navbar-nav ms-auto" v-b-scrollspy:nav-scroller>
            <b-nav-item href="#scrollspy_description" @click.stop="eventCancel" style="cursor: default"
              ><a href="#scrollspy_description" id="scrollspy_description_a" v-smooth-scroll>소개</a></b-nav-item
            >
            <b-nav-item href="#scrollspy_benefits" @click.stop="eventCancel" style="cursor: default"
              ><a href="#scrollspy_benefits" v-smooth-scroll>혜택</a></b-nav-item
            >
            <b-nav-item href="#scrollspy_case" @click.stop="eventCancel" style="cursor: default"
              ><a href="#scrollspy_case" v-smooth-scroll>사례</a></b-nav-item
            >
            <b-nav-item href="#scrollspy_price" @click.stop="eventCancel" style="cursor: default"
              ><a href="#scrollspy_price" v-smooth-scroll>요금</a></b-nav-item
            >
          </b-nav>
        </div>
      </div>
    </nav>

    <div id="nav-scroller" style="position: relative; overflow-y: scroll">
      <section class="bg-cover pt-7 py-xxl-10" id="scrollspy_description">
        <div class="container pt-lg-6 pt-xxl-8">
          <div class="row align-items-center">
            <div class="col-lg-8">
              <div class="small-text">
                요즘 트렌드는 영상이닷!<br />통화연결안내 <span class="weight700">음성</span>과
                <span class="weight700">영상</span>이 한번에~
              </div>
              <h2 class="big-text mt-2">
                소상공인의 사장님!
                <span class="yellow">가게 매출 시작</span>은<br /><span class="yellow">V링고</span>에서 나온다.
              </h2>
            </div>
            <div class="col-lg-4 pt-xxl-4">
              <div class="ms-lg-5">
                <img src="../assets/images/main-img.png" class="gif" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="real-time position-relative py-7 py-lg-6 py-xxl-10">
        <SubscribeList></SubscribeList>
      </section>
      <section class="bg-vringo position-relative pt-7 py-lg-6 py-xxl-10">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="text-center mb-8">
                <h2 class="mb-8 fs-42">
                  소상공인을 <span class="weight700">위한</span>!! 사장님 매출을 <span class="weight700">위해</span>!!
                </h2>
                <h6 class="tit-text mb-6 white weight700 font-white">V링고란?</h6>
                <p class="mb-0 fs-22">
                  고객이 사장님 가게에 전화를 걸면,<br />고객 휴대폰에 가게 안내멘트와 홍보영상을 보여주는 통화연결음
                  영상 서비스
                </p>
              </div>
            </div>
          </div>
          <div class="bg-purple border-radius mb-4 px-5">
            <div class="service-icon col-lg-10 pt-8 pb-6">
              <img src="../assets/images/img_vringo.png" class="w-100" />
            </div>
          </div>
        </div>
      </section>
      <!--
      <section class="section_banner bg-white">
        <div class="container">
          <div class="bg_icon row justify-content-start">
            <div class="col-lg-10 col-md-7 col-xxl-12">
              <h6 class="weight700 mt-4 f-30">
                통화연결음 음원을 내 스마트폰(휴대폰)에서도 사용하고 싶다면
                <span class="font-red">"플러스링"</span>
              </h6>
              <p class="fs-22 font-gray mt-2">
                무선 1회선 월 2,200원 (직원들도 같이 사용할 수 있어요. -통신사 kt, skt 가능)
              </p>
            </div>
          </div>
        </div>
      </section>
      -->
      <section id="scrollspy_benefits" class="section service bg-light">
        <div class="container">
          <div class="row justify-content-start title mb-4 pb-2">
            <h2 class="tit-text">
              뭐라고요? 연간 <span class="weight700">700,000</span>원 상당의 서비스가 첫 달 무료???
            </h2>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-4 my-4 pt-2">
              <div class="service-box text-center bg-white">
                <div class="number">
                  <img src="../assets/images/num-1.png" />
                </div>
                <div class="service-icon pt-6 pb-4 col-lg-6">
                  <img src="../assets/images/ico-service01.png" />
                </div>
                <div class="service-content mt-4">
                  <h5 class="mb-3 text-muted weight700">최초가입 = 첫달 무료</h5>
                  <p class="para-width f-20 mb-0">첫 달 이용료가 무료!<br />사용해 보시고 결정하세요~^^</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 my-4 pt-2">
              <div class="service-box text-center bg-white">
                <div class="number">
                  <img src="../assets/images/num-2.png" />
                </div>
                <div class="service-icon pt-6 pb-4 col-lg-6">
                  <img src="../assets/images/ico-service02.png" />
                </div>
                <div class="service-content mt-4">
                  <h5 class="mb-3 text-muted weight700">맞춤형 콘텐츠 제작</h5>
                  <p class="text-muted para-width f-20 mb-0">
                    우리가게 희망 멘트, 이미지<br />교체 요청 시, 전문가 제작
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 my-4 pt-2">
              <div class="service-box text-center bg-white">
                <div class="number">
                  <img src="../assets/images/num-3.png" />
                </div>
                <div class="service-icon pt-6 pb-4 col-lg-6">
                  <img src="../assets/images/ico-service03.png" />
                </div>
                <div class="service-content mt-4">
                  <h5 class="mb-3 text-muted weight700">3분이내 초간단 가입</h5>
                  <p class="text-muted para-width f-20 mb-0">번호만 남기시면<br />통화후 3분이면 가입</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-start title mt-10 mb-4 pb-2">
            <h2 class="tit-text">가게정보(홍보) 서비스 타사와 비교</h2>
          </div>
          <div class="tblComm">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <colgroup>
                <col width="10%" />
                <col width="30%" />
                <col width="30%" />
                <col width="30%" />
              </colgroup>
              <tr>
                <th scope="col">구분</th>
                <th scope="col">V링고 프리미엄</th>
                <th scope="col">영상제작 홍보</th>
                <th scope="col">전단지 배포</th>
              </tr>
              <tr>
                <th scope="row">이용료</th>
                <td class="border-x"><span class="weight700">11,000원</span><br />(음성3개, 영상 2개 무료)</td>
                <td>최소 <span class="weight700">100,000원</span> 부터<br />(1개 영상)</td>
                <td><span class="weight700">120,000</span>원<br />(400장 기준)</td>
              </tr>
              <tr>
                <th scope="row">혜택</th>
                <td class="border-x">
                  <ul class="list">
                    <li>영상과 음성이 각각</li>
                    <li>고급 2개</li>
                    <li>계절별 1개</li>
                    <li>인트로 2개+계절별 1개</li>
                    <li>셀프 무제한 제작</li>
                  </ul>
                </td>
                <td>
                  <ul class="list">
                    <li>영상 2번 ~3번 수정가능</li>
                  </ul>
                </td>
                <td>
                  <ul class="list">
                    <li>디자인 선택</li>
                    <li>2차 거래서 10%할인</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">장단점</th>
                <td class="border-x border-b">
                  <ul class="list">
                    <li>자동으로 사업장 홍보와 안내</li>
                    <li>홍보효과 및 가성비가 좋음</li>
                    <li>장소와 시간에 구애받지 않고, 홍보가능</li>
                  </ul>
                </td>
                <td>
                  <ul class="list">
                    <li>불특정 다수 대상이므로 홍보 효과가 미비</li>
                    <li>매장 내 TV을 통한 대상으로만 홍보 가능</li>
                  </ul>
                </td>
                <td>
                  <ul class="list">
                    <li>전단지 배포 비용이 많이 들어감</li>
                    <li>전단지 훼손율이 높음</li>
                    <li>사람이 배포하는 부분으로 전달율이 낮고, 클레임이 많음</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <section class="section bg-white" id="scrollspy_case">
        <div class="container">
          <div class="row justify-content-start title mb-4 pb-2">
            <h2 class="tit-text">가게의 시작을 알리려면, 무조건, v링고 이어야 한다.</h2>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="sub-txt text-center mb-8">
                <p class="mb-0">
                  <span class="pb-1 f-30 block">사장님 가게 V링고 사례해 보셨나요??</span>
                  고객 <span class="f-27">귀</span>( <img src="../assets/images/ico-ear.png" /> )에서는 가게 안내멘트가
                  나오고,고객 <span class="f-27">눈</span>( <img src="../assets/images/ico-eye.png" /> )으로는
                  홍보영상이 보입니다.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <RecommendAudioSample />
            <RecommendVideoSample />
          </div>
        </div>
      </section>
      <section class="section_banner">
        <div class="container">
          <div class="bg_icon2 row justify-content-start">
            <div class="col-lg-10 col-md-7 col-xxl-12">
              <h6 class="weight700 mt-4 f-30">
                영상이 보이지 않을 때에는
                <span class="font-red f-32"><span class="weight900">"보이는 ARS"</span> 앱 설치</span>
                후 보시면 잘 보입니다.
              </h6>
              <div class="row justify-content-between title pb-2">
                <div class="google">
                  <a href="#" class="btn arrow py-2" @click.stop="appClick">
                    <i class="ico-google"><img src="../assets/images/ico-google-w.png" /></i>
                    <span class="inline-block">보이는 ARS 구글플레이 다운로드 바로가기</span></a
                  >
                </div>
                <div class="phone-num f-30 weight700 pt-3">
                  <img src="../assets/images/ico-tel.png" class="baseline" />
                  <span class="tit-num">체험번호</span>&nbsp;<a href="#" @click.stop="telClick"
                    ><img src="../assets/images/txt_tel.png" class="baseline"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section bg-blue" id="scrollspy_price">
        <div class="container">
          <div class="row justify-content-start title mb-8 pb-2">
            <h2 class="tit-text">가게 첫인상을 만드는건!! V링고다.</h2>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-4 mt-8 mb-4 mb-xxl-10 pt-2">
              <div class="service-box text-center bg-white min647">
                <div class="tit first">
                  <img src="../assets/images/txt_01.png" />
                </div>
                <div class="service-content mt-xxl-2 pt-xxl-10 col-lg-11">
                  <h2 class="h2 weight500 mb-5">V링고 <span class="weight700">베이직</span></h2>
                  <img src="../assets/images/txt_price01.png" />
                  <p class="mt-3">
                    <a href="#" v-b-modal="'promotionSaveModal'" @click="modalClick(5)" class="btn btn-lg black"
                      >상담예약</a
                    >
                  </p>
                </div>
                <div class="row justify-content-between py-4 border-bottom mx-3">
                  <div class="col-lg-6 border-dashed px-0 col-xxl-6">
                    <div class="service-ment text-left">
                      <h6 class="h6 pb-1">음성 멘트 제작</h6>
                      <ul class="list f-20">
                        <li>성우 최초 1회</li>
                        <li>셀프 건당</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-6">
                    <div class="service-ment">
                      <h6 class="h6 pb-1">
                        <span class="border-radius red px-2">2만원 상당</span>
                      </h6>
                      <ul class="f-20">
                        <li>무료</li>
                        <li>5,000원</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between pt-4 mx-3">
                  <div class="col-lg-6 col-xxl-6 border-dashed px-0">
                    <div class="service-ment text-left">
                      <h6 class="h6 pb-1">영상 멘트 제작</h6>
                      <ul class="list f-20">
                        <li>최초 1회</li>
                        <li>셀프 건당</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-6">
                    <div class="service-ment">
                      <h6 class="h6 pb-1">
                        <span class="border-radius red px-2">6만원 상당</span>
                      </h6>
                      <ul class="f-20">
                        <li>무료</li>
                        <li>11,000원</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mt-8 mb-4 mb-xxl-10 pt-2">
              <div class="service-box text-center bg-white min647">
                <div class="tit second">
                  <img src="../assets/images/txt_02.png" />
                </div>
                <div class="service-content mt-xxl-2 pt-xxl-10 col-lg-11">
                  <h2 class="h2 weight500 mb-5">V링고 <span class="weight700">플러스</span></h2>
                  <img src="../assets/images/txt_price02.png" />
                  <p class="mt-3">
                    <a href="#" v-b-modal="'promotionSaveModal'" @click="modalClick(3)" class="btn btn-lg green"
                      >상담예약</a
                    >
                  </p>
                </div>
                <div class="row justify-content-between py-4 border-bottom mx-3">
                  <div class="col-lg-6 col-xxl-6 border-dashed px-0">
                    <div class="service-ment text-left">
                      <h6 class="h6 pb-1">음성 멘트 제작</h6>
                      <ul class="list f-20">
                        <li>성우 최초 1회</li>
                        <li>셀프 건당</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-6">
                    <div class="service-ment">
                      <h6 class="h6 pb-1">
                        <span class="border-radius green px-2">9만원 상당</span>
                      </h6>
                      <ul class="f-20">
                        <li>+ 2건</li>
                        <li>무제한</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between pt-4 mx-3">
                  <div class="col-lg-6 border-dashed px-0 col-xxl-6">
                    <div class="service-ment text-left">
                      <h6 class="h6 pb-1">영상 멘트 제작</h6>
                      <ul class="list f-20">
                        <li>최초 1회</li>
                        <li>셀프 건당</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-6">
                    <div class="service-ment">
                      <h6 class="h6 pb-1">
                        <span class="border-radius green px-2">30만원 상당</span>
                      </h6>
                      <ul class="f-20">
                        <li>+ 2건</li>
                        <li>무제한</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mt-8 mb-4 pt-2">
              <div class="service-box text-center bg-white min647">
                <div class="tit third">
                  <img src="../assets/images/txt_03.png" />
                </div>
                <div class="service-content mt-xxl-2 pt-xxl-10 col-lg-11">
                  <h2 class="h2 weight500 mb-5">V링고 <span class="weight700">프리미엄</span></h2>
                  <img src="../assets/images/txt_price03.png" />
                  <p class="mt-3">
                    <a href="#" v-b-modal="'promotionSaveModal'" @click="modalClick(4)" class="btn btn-lg blue"
                      >상담예약</a
                    >
                  </p>
                </div>
                <div class="row justify-content-between py-4 border-bottom mx-3">
                  <div class="col-lg-6 border-dashed px-0 col-xxl-6">
                    <div class="service-ment text-left">
                      <h6 class="h6 pb-1">음성 멘트 제작</h6>
                      <ul class="list f-20">
                        <li>성우 최초 1회</li>
                        <li>셀프 건당</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 pr-0 col-xxl-6">
                    <div class="service-ment">
                      <h6 class="h6 pb-1">
                        <span class="border-radius blue px-2">20만원 상당</span>
                      </h6>
                      <ul class="f-20">
                        <li>+ 3건<span class="f-16">(외 인트로 4건)</span></li>
                        <li>무제한</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between pt-4 mx-3">
                  <div class="col-lg-6 col-xxl-6 border-dashed px-0">
                    <div class="service-ment text-left">
                      <h6 class="h6 pb-1">영상 멘트 제작</h6>
                      <ul class="list f-20">
                        <li>최초 1회</li>
                        <li class="blank">&nbsp;</li>
                        <li>셀프 건당</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-6 pr-0">
                    <div class="service-ment">
                      <h6 class="h6 pb-1">
                        <span class="border-radius blue px-2">50만원 상당</span>
                      </h6>
                      <ul class="f-20">
                        <li>
                          + 3건<br /><span class="f-16 block line-height20">(외 1 계절별 총 4건)</span
                          ><span class="f-16 block line-height20">(외 시즌 인트로제공)</span>
                        </li>
                        <li>무제한</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-right font-white f-20">※ 모든 요금제는 부가세 포함가격입니다.</p>
        </div>
      </section>
      <div class="banner">
        <ul>
          <li>
            <a href="#" @click.stop="telClick2"><img src="../assets/images/banner.gif" /></a>
          </li>
          <li>
            <a href="#" v-b-modal="'promotionSaveModal'" @click.stop="modalClick(3)"
              ><img src="../assets/images/banner_01.png"
            /></a>
          </li>
        </ul>
      </div>
      <div class="layer-tell">
        <ul>
          <li>
            <a href="#" @click.stop="telClick2"
              ><i class="ico-phone"><img src="../assets/images/ico-tel.png" /></i><span class="tell">1577-1361</span></a
            >
          </li>
          <li>
            <a href="#" class="blink" v-b-modal="'promotionSaveModal'" @click.stop="modalClick(3)"
              >예<span class="weight500 f-16 align-middle pb-2 inline-block">/</span>약<span
                class="weight500 f-16 pb-2 align-middle inline-block"
                >/</span
              >하<span class="weight500 f-16 pb-2 align-middle inline-block">/</span>기</a
            >
          </li>
        </ul>
      </div>
    </div>
    <PromotionSaveModal ref="saveModal"></PromotionSaveModal>
  </div>
</template>

<script>
import RecommendAudioSample from './RecommendAudioSample.vue'
import RecommendVideoSample from './RecommendVideoSample.vue'
import PromotionSaveModal from './PromotionSaveModal.vue'
import SubscribeList from './SubscribeList.vue'
export default {
  components: {
    RecommendAudioSample,
    RecommendVideoSample,
    PromotionSaveModal,
    SubscribeList,
  },
  methods: {
    eventCancel(event) {
      if (event.target.children.length > 0) {
        event.target.children[0].click()
      }
      event.preventDefault()
    },
    modalClick(ringoPart) {
      this.$refs['saveModal'].setRingoPart(ringoPart)
    },
    telClick() {
      if (this.isMobile()) {
        location.href = 'tel:02-2028-1916'
      } else {
        alert('소지하고 계신 휴대폰으로 전화를 하시면 V링고 프리미엄을 체험하실 수 있습니다.')
      }
    },
    telClick2() {
      location.href = 'tel:1577-1361'
    },
    appClick() {
      const ua = navigator.userAgent.toLowerCase()
      if (this.isMobile()) {
        if (ua.indexOf('android') > -1) {
          window.open('intent://callgate.com/#Intent;scheme=privacy;package=com.callgate.ars;end')
        } else {
          window.open('https://apps.apple.com/kr/app/%EB%B3%B4%EC%9D%B4%EB%8A%94-ars/id1286982466')
        }
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.callgate.ars&hl=ko&gl=US')
      }
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    },
    handleResize() {
      const height = window.innerHeight
      this.setScrollHeight(height)
    },
    setScrollHeight(height) {
      document.getElementById('nav-scroller').style.height = `${height}px`
    },
  },
  mounted() {
    const height = window.innerHeight

    this.setScrollHeight(height)
    // console.log("ready...");
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>
