const videoArray = [
  {
    src: require('@/assets/resources/video/01. 음식점_돈까스.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/01. 음식점_돈까스.png'),
    style: '타이포',
    category: '음식점_돈까스',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/02. 교육.학원_어린이집.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/02. 교육.학원_어린이집.png'),
    style: '컬러풀',
    category: '교육,학원_어린이집',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/03. 스포츠_스크린골프.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/03. 스포츠_스크린골프.png'),
    style: '다이나믹',
    category: '스포츠_스크린골프',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/04. 병원_소아과.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/04. 병원_소아과.png'),
    style: '심플',
    category: '병원_소아과',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/05. 생활.편의_공인중개사사무소.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/05. 생활.편의_공인중개사사무소.png'),
    style: '전단지',
    category: '생활.편의_공인중개사사무소',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/06. 숙박_호텔.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/06. 숙박_호텔.png'),
    style: '전단지',
    category: '숙박_호텔',
    genre: '캠페인',
  },
  {
    src: require('@/assets/resources/video/07. 프렌차이즈.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/07. 프렌차이즈.png'),
    style: '다이나믹',
    category: '프렌차이즈',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/08. 미용_마사지.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/08. 미용_마사지.png'),
    style: '전단지',
    category: '미용_마사지',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/09. 디저트카페.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/09. 디저트카페.png'),
    style: '다이나믹',
    category: '디저트카페',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/10. 쇼핑_가구.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/10. 쇼핑_가구.png'),
    style: '컬러풀',
    category: '쇼핑_가구',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/11. 편의점.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/11. 편의점.png'),
    style: '심플',
    category: '편의점',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/12. 횟집.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/12. 횟집.png'),
    style: '전단지',
    category: '횟집',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/13. 분식.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/13. 분식.png'),
    style: '컬러풀',
    category: '분식',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/14. 피자.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/14. 피자.png'),
    style: '컬러풀',
    category: '피자',
    genre: '캠페인',
  },
  {
    src: require('@/assets/resources/video/15. 세계음식_태국.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/15. 세계음식_태국.png'),
    style: '다이나믹',
    category: '세계음식_태국',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/16. 자동차_세차.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/16. 자동차_세차.png'),
    style: '컬러풀',
    category: '자동차_세차',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/17. 회사_화장품.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/17. 회사_화장품.png'),
    style: '심플',
    category: '회사_화장품',
    genre: '기본음',
  },
  {
    src: require('@/assets/resources/video/18. 반찬가게.배달전문.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/18. 반찬가게.배달전문.png'),
    style: '전단지',
    category: '반찬가게.배달전문',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/19. 주점_막걸리.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/19. 주점_막걸리.png'),
    style: '타이포',
    category: '주점_막걸리',
    genre: '요일시간대',
  },
  {
    src: require('@/assets/resources/video/20. 고깃집.mp4'),
    thumbnail: require('@/assets/resources/video/thumbnail/20. 고깃집.png'),
    style: '타이포',
    category: '고깃집',
    genre: '기본음',
  },
]

const audioArray = [
  {
    num: 1,
    src: require('@/assets/resources/audio/01. 음식점(한식,중식,일식).wav'),
    setting: '24시간',
    category: '음식점(한식/중식/일식)',
    genre: '기본음',
  },
  {
    num: 2,
    src: require('@/assets/resources/audio/02. 교육학원(학원,어린이집,태권도).wav'),
    setting: '24시간',
    category: '교육/학원(학원/어린이집/태권도)',
    genre: '기본음',
  },
  {
    num: 3,
    src: require('@/assets/resources/audio/03. 스포츠(당구,골프,스크린).wav'),
    setting: '24시간',
    category: '스포츠(당구/골프/스크린)',
    genre: '기본음',
  },
  {
    num: 4,
    src: require('@/assets/resources/audio/04. 병원,약국.wav'),
    setting: '21일~23일',
    category: '병원/약국',
    genre: '캠페인',
  },
  {
    num: 5,
    src: require('@/assets/resources/audio/05. 생활,편의(공인중개사사무소).wav'),
    setting: '24시간',
    category: '생활/편의(공인중개사사무소)',
    genre: '기본음',
  },
  {
    num: 6,
    src: require('@/assets/resources/audio/06. 숙박(모텔,호텔).wav'),
    setting: '21일~25일',
    category: '숙박(모텔/호텔)',
    genre: '캠페인',
  },
  {
    num: 7,
    src: require('@/assets/resources/audio/07. 프렌차이즈.wav'),
    setting: '21일~27일',
    category: '프렌차이즈',
    genre: '캠페인',
  },
  {
    num: 8,
    src: require('@/assets/resources/audio/08. 미용(마사지,스파,미용실).wav'),
    setting: '오후9시~다음날 오전10시',
    category: '미용(마사지/스파/미용실)',
    genre: '요일시간대',
  },
  {
    num: 9,
    src: require('@/assets/resources/audio/09. 카페.wav'),
    setting: '오전9시~오후7시',
    category: '카페',
    genre: '요일시간대',
  },
  {
    num: 10,
    src: require('@/assets/resources/audio/10. 쇼핑(전기,정육점,가구,애견용품 등).wav'),
    setting: '24시간',
    category: '쇼핑(전기/정육점/가구/애견용품 등)',
    genre: '기본음',
  },
  {
    num: 11,
    src: require('@/assets/resources/audio/11. 편의점.wav'),
    setting: '24시간',
    category: '편의점',
    genre: '기본음',
  },
  {
    num: 12,
    src: require('@/assets/resources/audio/12. 횟집,스시.wav'),
    setting: '24시간',
    category: '횟집/스시',
    genre: '기본음',
  },
  {
    num: 13,
    src: require('@/assets/resources/audio/13. 분식.wav'),
    setting: '24시간',
    category: '분식',
    genre: '기본음',
  },
  {
    num: 14,
    src: require('@/assets/resources/audio/14. 치킨,피자.wav'),
    setting: '24시간',
    category: '치킨/피자',
    genre: '기본음',
  },
  {
    num: 15,
    src: require('@/assets/resources/audio/15. 세계음식.wav'),
    setting: '24시간',
    category: '세계음식',
    genre: '기본음',
  },
  {
    num: 16,
    src: require('@/assets/resources/audio/16. 자동차(카센터,세차).wav'),
    setting: '한달간',
    category: '자동차(카센터/세차)',
    genre: '캠페인',
  },
  {
    num: 17,
    src: require('@/assets/resources/audio/17. 제조업,건설업,회사법인.wav'),
    setting: '매달 5일',
    category: '제조업/건설업/회사법인',
    genre: '캠페인',
  },
  {
    num: 18,
    src: require('@/assets/resources/audio/18. 밀키트,반찬가게,배달전문.wav'),
    setting: '24시간',
    category: '밀키트/반찬가게/배달전문',
    genre: '기본음',
  },
  {
    num: 19,
    src: require('@/assets/resources/audio/19. 호프,주점,와인.wav'),
    setting: '24시간',
    category: '호프/주점/와인',
    genre: '기본음',
  },
  {
    num: 20,
    src: require('@/assets/resources/audio/20. 고깃집.wav'),
    setting: '24시간',
    category: '고깃집',
    genre: '기본음',
  },
]

module.exports = {
  videoArray,
  audioArray,
}
