<template>
  <div class="col-lg-6 mb-4 pt-2">
    <div class="service-box text-center bg-common">
      <div class="service-icon py-4 px-2">
        <img src="../assets/images/img-02.gif" class="w-100" />
      </div>
      <div class="service-content mt-4">
        <div class="service-content mt-4">
          <img src="../assets/images/img-tit2.png" />
        </div>
        <div class="tblComm2">
          <div class="row justify-content-between">
            <div v-for="(item, index) in showVideoArray" class="col-lg-4 col-xxl-6" :key="'video_' + index">
              <div class="text-center">
                <div class="service-content mt-4">
                  <button type="button" v-b-modal="'videoModal'" @click="videoClick(item)">
                    <img :src="item.thumbnail" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PlayVideoModal :videoItem="videoItem"></PlayVideoModal>
  </div>
</template>

<script>
import PlayVideoModal from '@/components/PlayVideoModal.vue'
import { videoArray } from '@/api/array.js'

export default {
  data: () => ({
    videoItem: {},
    count: 0,
    countInterval: undefined,
  }),
  computed: {
    showVideoArray() {
      const array = videoArray.slice(this.count % 20, (this.count % 20) + 6)
      if (array.length < 6) {
        array.push(videoArray[0])
      }
      if (array.length < 6) {
        array.push(videoArray[1])
      }
      if (array.length < 6) {
        array.push(videoArray[2])
      }
      if (array.length < 6) {
        array.push(videoArray[3])
      }
      if (array.length < 6) {
        array.push(videoArray[4])
      }
      return array
    },
  },
  mounted() {
    this.countInterval = setInterval(() => {
      this.count += 1
    }, 3000)
  },
  updated() {
    if (this.countInterval) {
      clearInterval(this.countInterval)
      this.countInterval = setInterval(() => {
        this.count += 1
      }, 3000)
    }
  },
  methods: {
    videoClick(item) {
      this.videoItem = item
    },
  },
  components: {
    PlayVideoModal,
  },
}
</script>
